import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {toggleLoader} from "../../shared/actions/setting";
import {hasPermission} from "../../utils/Authentication";
import {toast} from "react-toastify";
import * as _ from "underscore";
import FormHandler from "../../shared/utils/FormHandler";
import {buyerAdd, contactPersonAdd, purchasingPowerDetailsAdd} from "../../utils/FormValidationRules";
import AddProcurements from "./add-procurement-list";
import { uploadImage, getDistricts, formatDisplayEnumValue } from "../../utils/Utils";
import FarmerLocationAdd from "../xindicate/participant/farmer-location-add";
import file from "../../images/file-upload.png";
import fileIcon from "../../images/document.png";
import {FileUploader} from "react-drag-drop-files";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {MultiSelect} from "react-multi-select-component";
import { getSelectedBuyer } from "../../actions/module";

export default function AddBuyer(props) {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleProcurements, setIsVisibleProcurements] = useState(false);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [optionsArray, setOptionsArray] = useState([]);
  const [cropsArray, setCropsArray] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);
  const [selectedProcurement, setSelectedProcurement] = useState(null);
  const [isImg, setIsImg] = useState({});
  const [steps, setSteps] = useState(0);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    initForm,
    deleteErrors,
    setValue
  } = FormHandler(buyer, steps === 2 ? contactPersonAdd :  steps === 1 ? purchasingPowerDetailsAdd : buyerAdd);

  const marketOption = [{label: "Nation wide", value: "NATION_WIDE"}, {label: "Provincial wide", value: "PROVINCIAL_WIDE" },
                       {label: "Export", value: "EXPORT"}]

  function buyer() {
    if (steps === 2) {
      setSteps(steps + 1);
      setIsLoading(true);
    }
    setSteps(steps + 1);
  }

  function handleChangeGender(e) {
    setValue({gender: e.target.value});
  }

  const fileTypes = ["png", "gif", "jpeg", "jpg"];

  function onTypeError(e) {
    toast.error(e)
  }

  function resetForm() {
    const values = {};
    initForm({...values});
    Object.keys(values).forEach(key => delete values[key]);
    deleteErrors(errors);
    props.setIsUpdateAvailable(false);
    setIsImg({})
  }

  function onClose() {
    setIsVisibleProcurements(false);
    setSelectedProcurement(null);
  }

  function onChangeProcurement(field) {
    if (values.procurementList && field.index) {
      let index = field.index - 1;
      delete field.index;
      values.procurementList[index] = field;
      setValue({procurementList: values.procurementList});
    } else if (values.procurementList) {
      setValue({procurementList: [...values.procurementList, field]});
    } else {
      setValue({procurementList: [field]});
    }
    setIsVisibleProcurements(false);
    setSelectedProcurement(null);
  }

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(
      process.env.REACT_APP_HOST + `/user/` + getUserId() + `/cropAgronomy`
    )
      .then((res) => {
        setCropsArray(res.data.content);
        setCategoryArray(_.pluck(res.data.content, 'category'))
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, []);

  useEffect(() => {
    if (!props.isUpdateAvailable) return;

    Object.keys(errors).forEach(k => errors[k] = "");
    let index = props.buyerList.findIndex(id => id.id === props.buyerId)
    initForm(props.buyer ? props.buyer : props.buyerList[index]);

    let tempArray = props.buyer ? props.buyer.markets : props.buyerList[index].markets
    if(tempArray){
      setSelectedMarkets(tempArray.map(item => {
        return {label: formatDisplayEnumValue(item), value: item}
    }))
    }
    setSteps(0)
  }, [props.isUpdateAvailable]);

  useEffect(async () => {
    if (!isLoading || steps !== 3) { 
      return;
    }
    dispatch(toggleLoader(true));

    if (isImg.add) {
      let response = await uploadImage(isImg.imgName, isImg.file);
      values.companyLogo = response;
      setIsImg({...isImg, add: false});
      dispatch(toggleLoader(false));
      setSteps(0)

    } else {
      if (!props.isUpdateAvailable) {
        axios.post(process.env.REACT_APP_HOST + '/user/' + getUserId() + '/buyer', values)
          .then(res => {
            toast.success("Buyer added successfully");
            props.setIsUpdate(!props.isUpdate)
            resetForm();
          })
          .catch(error => {
            toast.error("Error occured!");
            if (error.response && error.response.status === 422) {
            }
          })
          .finally(() => {
            setIsLoading(false);
            dispatch(toggleLoader(false));
            props.setIsModalVisible(false);
          });
      } else {
        delete values.id;
        axios.put(process.env.REACT_APP_HOST + '/user/' + getUserId() + '/buyer/' + props.buyerId, values)
          .then(res => {
            toast.success("Buyer updated successfully");
            let index = props.buyerList.findIndex(id => id.id === props.buyerId || !id.id)
            const updatedBuyerList = [...props.buyerList];
            updatedBuyerList[index] = res.data.content;
            props.setBuyerList(updatedBuyerList);
            dispatch(getSelectedBuyer(res.data.content))
            resetForm();
          })
          .catch(error => {
            toast.error("Error occured!");
          })
          .finally(() => {
            setIsLoading(false);
            dispatch(toggleLoader(false));
            props.setIsModalVisible(false);
          });
      }
    }
  }, [isLoading, isImg.add]);

  function marketOnChange(selected) {
    setSelectedMarkets(selected);
    let marketList = []
    for (let i = 0; i < selected.length; i++) {
      marketList.push(selected[i].value)
    }
    setValue({markets: marketList});
  }

  function buyerGeoLocation(position) {
    setValue({coordinates: position});
  }

    return (
       <div>
        <div className="sa-popup-bg ">
          <div className="sa-popup">
            <form onSubmit={handleSubmit} className={'sa-modal-box-style'}>
              <div className="sa-popup-header">
                <span
                  className={'sa-model-heading'}>{values.id ? t("title.EDIT_BUYER") : t("title.ADD_BUYER")}</span>
                <div className="sa-popup-close-icon"><FeatherIcon
                  onClick={() => {
                    props.setIsModalVisible(false);
                    resetForm();
                  }}
                  className={"sa-modal-close-icon"} icon={"x"}/>
                </div>
              </div>
              <div className=" ">
                <div
                  className={
                    "d-flex justify-content-center align-items-center connector-container mb-5 mt-3"
                  }
                >
                  <div className={"d-flex flex-column align-items-center"}>
                    <div
                      className={
                        "step-number " + (steps === 0 ? "active-step" : " ")
                      }
                    >
                      01
                    </div>
                    <div
                      className={"steps-txt " + (steps === 0 ? "active-txt" : " ")}
                    >
                      {t("title.BASIC_DETAILS")}
                    </div>
                  </div>


                  <div className={"d-flex flex-column align-items-center"}>
                    <hr className={"connector-hr-line-for-buyer-stages-left"}/>
                    <div
                      className={
                        "step-number " + (steps === 1 ? "active-step" : " ")
                      }
                    >
                      02
                    </div>
                    <div
                      className={"steps-txt " + (steps === 1 ? "active-txt" : " ")}
                    >
                      {t("title.PURCHASING_POWER_DETAILS")}
                    </div>
                  </div>


                  <div className={"d-flex flex-column align-items-center"}>
                    <hr className="connector-hr-line-for-buyer-stages-right"/>
                    <div
                      className={
                        "step-number " + (steps === 2 ? "active-step" : " ")
                      }
                    >
                      03
                    </div>
                    <div
                      className={"steps-txt " + (steps === 2 ? "active-txt" : " ")}
                    >
                      {t("title.CONTACT_PERSON_DETAILS")}
                    </div>
                  </div>
                </div>

              <div className="sa-popup-content" hidden={steps !== 0}>
                <div className="col-md-6 ">
                  <div className="form-group m-b-16">
                    <label>{t("title.COMPANY_NAME")}</label>
                    <input onBlur={handleOnBlur} onChange={handleChange}
                           value={values.companyName || ''} type="text"
                           className={`form-control ${errors.companyName && "warning-input"}`}
                           name="companyName"
                           placeholder={t("placeHolder.ENTER_COMPANY_NAME")}/>
                    {errors.companyName && (<p className="warning-input-msg ">{errors.companyName}</p>)}
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="form-group m-b-16">
                    <label>{t("title.REGISTRATION_NUMBER")}</label>
                    <input onBlur={handleOnBlur} onChange={handleChange}
                           value={values.registrationNo || ''} type="text"
                           className={`form-control ${errors.registrationNo && "warning-input"}`}
                           name="registrationNo"
                           placeholder={t("placeHolder.ENTER_REGISTRATION_NUMBER")}/>
                    {errors.registrationNo && (<p className="warning-input-msg ">{errors.registrationNo}</p>)}
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="form-group m-b-16">
                    <label >{t("title.ADDRESS")}</label>
                    <input onBlur={handleOnBlur} onChange={handleChange}
                           value={values.address || ''} type="text"
                           className={`form-control ${errors.address && "warning-input"}`}
                           name="address"
                           placeholder={t("placeHolder.ENTER_ADDRESS")}/>
                      {errors.address && (<p className="warning-input-msg ">{errors.address}</p>)}
                  </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label>{t("title.DISTRICT")}</label>
                      <select
                        onBlur={handleOnBlur}
                        onChange={handleChange}
                        value={values.district || ""}
                        type="text"
                        className={`form-control ${errors.district && "warning-input"}`}
                        name="district"
                        placeholder={t("placeHolder.SELECT_DISTRICT")}
                      >
                        <option value={""}>{t("placeHolder.SELECT_DISTRICT")} </option>
                        {getDistricts().map((item, index) => (
                          <option key={index} value={item.toUpperCase().replaceAll(' ', '_')}>{item}</option>
                        ))}
                      </select>
                      {errors.district && (<p className="warning-input-msg ">{errors.district}</p>)}
                    </div>
                  </div>
                <div className="col-md-6">
                <div className="form-group">
                  <label>{t("title.CATEGORY")}</label>
                  <select
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.category || ""}
                    type="text"
                    className={`form-control`}
                    name="category"
                    placeholder={t("title.SELECT_CAREGORY")}
                  >
                    <option value={""}>{t("title.SELECT_CAREGORY")} </option>
                    <option value="SOLE_PROSPERITY">{t("title.SOLE_PROSPERITY")}</option>
                    <option value="PARTNERSHIP">{t("title.PARTNERSHIP")}</option>
                    <option value="PVT_LTD">{t("title.PVT_LTD")}</option>
                    <option value="PLC">{t("title.PLC")}</option>
                    <option value="OTHERS">{t("title.OTHERS")}</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("title.COMPANY_NATURE")}</label>
                  <select
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.companyNature || ""}
                    type="text"
                    className={`form-control`}
                    name="companyNature"
                    placeholder={t("placeHolder.SELECT_COMPANY_NATURE")}
                  >
                    <option value={""}>{t("placeHolder.SELECT_COMPANY_NATURE")} </option>
                    <option value="RETAILS_CHAIN">{t("title.RETAILS_CHAIN")}</option>
                    <option value="SME">{t("title.SME")}</option>
                    <option value="WHOLE_SELLER">{t("title.WHOLE_SELLER")}</option>
                    <option value="VALUE_ADDITION">{t("title.VALUE_ADDITION")}</option>
                    <option value="PROCESSING">{t("title.PROCESSING")}</option>
                    <option value="OTHERS">{t("title.OTHERS")}</option>
                  </select>
                </div>
              </div>

              <div className="col-md-12">
                  <div className="form-group m-b-16 d-flex flex-column">
                    <label>{t("title.COMPANY_LOGO")}</label>
                    <FileUploader handleChange={(data) => {
                      setIsImg({add: true, imgName: data.name, file: data, disabled: true})

                    }} disabled={isImg.disabled} name="file" types={fileTypes} multiple={false}
                                  onTypeError={onTypeError}>

                      <div className={"upload-img-farmer"}>
                        {isImg.imgName && (
                          <div
                            className="sa-popup-close-icon "
                            style={{transform: "translate(600px,-10px)"}}
                            onClick={() => setIsImg({})}
                          >
                            <FeatherIcon
                              className={"sa-modal-close-icon"}
                              icon={"x"}
                            />
                          </div>
                        )}

                        <div>
                          <label htmlFor="file-upload-pro">
                            {!isImg.imgName && (
                              <div
                                style={{cursor: "pointer"}}
                                className={"text-center mt-2"}
                              >
                                <div className={"text-center"}>
                                  <img
                                    src={file}
                                    className={"mr-3 upload-img"}
                                    width={"50px"}
                                  />
                                </div>
                                <div>
                                  {t(
                                    "instructions.DRAG_&_DROP_FILE_HERE_TO_UPLOAD_OR"
                                  )}
                                  <span className={"text-primary"}>
                                                            {" "}
                                    {t("title.BROWSE")}
                                                        </span>
                                </div>
                              </div>
                            )}
                            {isImg.imgName && (
                              <div
                                style={{cursor: "pointer"}}
                                className="table-actions"
                                data-toggle="tooltip"
                                title={isImg.imgName}
                              >
                                <div
                                  className={
                                    "text-center mt-2 photo-upload-background"
                                  }
                                >
                                  <img
                                    src={fileIcon}
                                    className={"mr-3 upload-img photo-upload-img"}
                                    width={"50px"}
                                  />
                                  <div className={"photo-upload"}>
                                    <div className={"photo-upload-name"}>
                                      {" "}
                                      {isImg.imgName.slice(0, 33) +
                                        (isImg.imgName.length >= 33
                                          ? "...."
                                          : "")}
                                    </div>
                                    <div className={"photo-upload-size"}>
                                      {Math.round(isImg.file.size / 1028) +
                                        "KB"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </label>
                        </div>
                      </div>
                    </FileUploader>

                    <input id="file-upload-pro" type="file" className={'invisible'} onChange={(e) => {
                      setIsImg({add: true, image: e.target.files[0].name, file: e.target.files[0]})

                    }} name={'file'} accept="image/png, image/gif, image/jpeg"/>
                  </div>
                </div>

                <div className="col-md-12 mb-3">
                <label>{t("title.COMPANY_LOCATION")}</label>
                      <FarmerLocationAdd onChange={buyerGeoLocation}
                                        location={values?.coordinates}/>
                </div>
              </div>

              <div className="sa-popup-content" hidden={steps !== 1}>

                    <label>{t("title.MARKETS")}</label>
                    <div className={`form-control px-0`}>
                      <MultiSelect
                        options={marketOption}
                        value={selectedMarkets}
                        onChange={marketOnChange}
                        ClearSelectedIcon
                      />
                    </div>
                
                <div className="col-md-6 activityAddField">
                  <div className="form-group m-b-16">
                    <span className={'sa-table-icon-flex'}>
                      <label class="labelSize-HH">{t("title.PROCUREMENTS")}</label>
                        <button className="btn btn-sa-secondary pis-add-activityFields" type={"button"}
                          onClick={() => {
                            setIsVisibleProcurements(true)
                          }}>
                      <FeatherIcon
                        className={"icon-in-btn"} icon={"plus"}/> <span className={"hide-375"}></span>
                    </button>
                  </span>
                  {errors.procurementList && (<p className="warning-input-msg ">{errors.procurementList}</p>)}
                  </div>
                </div>

                <div className="sa-table-container mt-0">
                  <table className="table table-borderless">
                    <thead>
                    <tr>
                      <th className={'sa-table-head-sticky'}>{t("title.ITEMS")}</th>
                      <th className={'sa-table-head-sticky'}>{t("title.CATEGORY")}</th>
                      <th className={'sa-table-head-sticky'}>{t("title.REQUIREMENT")}</th>
                      <th className={'sa-table-item-align sa-table-head-sticky'}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {values.procurementList && values.procurementList.map((item, index) =>
                      <tr key={index}>
                        <td className="sa-table-data">{item.item}</td>
                        <td className="sa-table-data">{item.category}</td>
                        <td className="sa-table-data">{item.requirement}</td>
                        {hasPermission("WRITE") &&
                          <td className="sa-table-item-align">
                            <div className={'sa-table-action-flex'}>
                              <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                                   title="Edit">
                                <FeatherIcon
                                  onClick={() => {
                                    setIsVisibleProcurements(true);
                                    item.index = index + 1
                                    setSelectedProcurement(item)
                                    Object.keys(errors).forEach(k => errors[k] = "");
                                  }} className="table-action" icon={"edit"}/></div>

                              <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                                   title="Delete Permanently">
                                <FeatherIcon
                                  onClick={() => {
                                    const updatedProcurementList = [...values.procurementList];
                                    updatedProcurementList.splice(index, 1);
                                    setValue({procurementList: updatedProcurementList})
                                  }}
                                  className="text-red" icon={"trash-2"}/></div>
                            </div>
                          </td>
                        }
                      </tr>)}
                    </tbody>
                  </table>
                  {(!values.procurementList || values.procurementList?.length === 0) && (
                    <div className={"empty-results"}>
                      <FeatherIcon icon="info"/>
                      <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_PROCUREMENTS")}</div>
                    </div>)
                  }
                </div>
              </div>

              <div className="sa-popup-content" hidden={steps !== 2 && steps !== 3}>
                <div className="col-md-6 ">
                  <div className="form-group m-b-16">
                    <label>{t("title.CONTACT_PERSON_NAME")}</label>
                    <input onBlur={handleOnBlur} onChange={handleChange}
                           value={values.contactName || ''} type="text"
                           className={`form-control ${errors.contactName && "warning-input"}`}
                           name="contactName"
                           placeholder={t("placeHolder.ENTER_CONTACT_PERSON_NAME")}/>
                    {errors.contactName && (<p className="warning-input-msg ">{errors.contactName}</p>)}
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="form-group m-b-16">
                    <label>{t("title.CONTACT_NUMBER")}</label>
                    <input onBlur={handleOnBlur} onChange={handleChange}
                           value={values.contactNumber || ''} type="text"
                           className={`form-control ${errors.contactNumber && "warning-input"}`}
                           name="contactNumber"
                           placeholder={t("placeHolder.ENTER_CONTACT_NUMBER")}/>
                    {errors.contactNumber && (<p className="warning-input-msg ">{errors.contactNumber}</p>)}
                  </div>
                </div>
                <div className="col-md-6 ">
                <div className="form-group m-b-16">
                        <label>{t("title.NIC_NO")}</label>
                        <input
                          onBlur={handleOnBlur}
                          onChange={handleChange}
                          value={values.nicNumber || ""}
                          type="text"
                          className={`form-control ${errors.nicNumber && "warning-input"}`}
                          name="nicNumber"
                          placeholder={t("placeHolder.ENTER_NIC_NO")}
                        />
                        {errors.nicNumber && (<p className="warning-input-msg ">{errors.nicNumber}</p>)}
                      </div>
                </div>
                <div className="col-md-6 ">
                  <div className="form-group m-b-16">
                          <label htmlFor="email">{t("title.EMAIL_ADDRESS")}</label>
                          <input
                            onBlur={handleOnBlur}
                            onChange={handleChange}
                            value={values.email || ""}
                            type="text"
                            className={`form-control ${
                              errors.email && "warning-input"
                            }`}
                            name="email"
                            placeholder={t("placeHolder.ENTER_EMAIL_ADDRESS")}
                          />
                          {errors.email && (
                            <p className="warning-input-msg ">{errors.email}</p>
                          )}
                  </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label>{t("title.GENDER")}</label>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="inlineRadio1"
                            value={"MALE"}
                            checked={values.gender === "MALE"}
                            onChange={handleChangeGender}
                            onBlur={handleOnBlur}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            {t("title.MALE")}
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="inlineRadio2"
                            value={"FEMALE"}
                            checked={values.gender === "FEMALE"}
                            onChange={handleChangeGender}
                            onBlur={handleOnBlur}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            {t("title.FEMALE")}

                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="inlineRadio3"
                            value={"NOT_SPECIFIED"}
                            checked={values.gender === "NOT_SPECIFIED"}
                            onChange={handleChangeGender}
                            onBlur={handleOnBlur}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio3"
                          >
                            {t("title.NOT_SPECIFIED")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

              <div className="sa-popup-btn m-t-16">
                <button id="btnCancel" type={"button"} onClick={() => {
                  steps === 0 ? props.setIsModalVisible(false) : setSteps(steps - 1);
                }}
                className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style">
                  {steps === 0 ? t("button.CANCEL") : t("button.BACK")}
                </button>
                <button id="btnAdd"
                  className="sa-popup-secondary-btn-style">
                    {steps > 1 ? props.isUpdateAvailable ? t("button.UPDATE") : t("button.ADD") : t("button.NEXT")}
                </button>
              </div>
             </div>
            </form>
          </div>
        </div>
      {isVisibleProcurements &&
        <AddProcurements selectedItem={selectedProcurement} onClose={() => onClose()} onChange={(e) => onChangeProcurement(e)}
                          crops={cropsArray} category={categoryArray} />
      }
    </div>
    )
}